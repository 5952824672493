.larger {
  font-size: 120%;
}

.text-white {
  color: #fff;
}

.form-inline [class*="col-"] .form-control {
  @media (min-width: 768px) {
    width: 100%;
  }
}

.form-control {
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

.pad-1x {
  padding: 1em;
}

.pad-top-x {
  padding-top: 0;
}

.pad-top_5x {
  padding-top: 0.5em;
}

.pad-top-1x {
  padding-top: 1em;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin_5x {
  margin: 0.5em;
}

.margin-vert-x {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-top_5x {
  margin-top: 0.5em;
}

.margin-top-4x {
  margin-top: 4em;
}

.margin-bottom-2x {
  margin-bottom: 2em;
}

.margin-left_5x {
  margin-left: 0.5em;
}

.margin-right_5x {
  margin-right: 0.5em;
}

.btn {
  white-space: normal;
}

a {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
  }
}

.navbar-default .navbar-collapse {
  @media (min-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
